import React, { useLayoutEffect } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { Fragment } from "preact"
import { DOMAIN } from "../constants"
import { Ad } from "../components/ads/ad-top"

const renderItem = (data, key) => {
  if (data.text) {
    return <p key={key}>{data.text}</p>
  }

  if (data.url) {
    return <img key={key} src={data.url} alt="Rugaciuni Dimineata" />
  }

  return null
}

const PageComponent = ({ pageContext, uri }) => {
  const data = pageContext.data
  const title = data.title.text
  const items = data.content.raw
  const icon = data.icon && data.icon.url
  const description = items[0].text

  useLayoutEffect(() => {
    const YES = typeof FB !== "undefined"
    if (YES) {
      window.FB.XFBML.parse()
    }
  })

  return (
    <Layout image={icon} uri={uri} title={title} description={description}>
      <article>
        <Ad type={1} />
        <div className="crumbs">
          <Link to="/">Acasa</Link> &raquo; {title}
        </div>
        <h2>{title}</h2>
        <div className="social">
          <div
            className="fb-like"
            data-href={DOMAIN + uri}
            data-width=""
            data-layout="button_count"
            data-action="like"
            data-size="small"
            data-show-faces="true"
            data-share="true"
          ></div>
        </div>
        {icon && renderItem(data.icon)}
        {items &&
          items.map((item, i) => (
            <Fragment key={i}>{renderItem(item, i)}</Fragment>
          ))}
      </article>
    </Layout>
  )
}

export default PageComponent
