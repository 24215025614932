import React from "react"

export const Ad = ({ type }) => {
  switch (type) {
    case 1:
      return (
        <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client="ca-pub-5701860158578711"
          data-ad-slot="5070499679"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      )

    default:
      return null
  }

  return null
}
